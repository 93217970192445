import { atomFamily, selectorFamily } from 'recoil';
import { EntityId, Product, ProductRecord } from '../../@sprinx/knihovka-types';
import { ApiClient } from '../../@sprinx/react-after-razzle';
import { GlobalStateRegister } from '../../@sprinx/react-after-razzle/stateStore';
import {
  apiClientState,
  currencyState,
  decimalPrecisionState,
  localeState,
  pricesTypeState,
  regionState,
} from '../appState';

export interface ProductDetailProduct
  extends Product<
    ProductRecord,
    | 'displayNameAsName'
    | 'media'
    | 'parameters'
    | 'prices'
    | 'pricingExtensions'
    | 'adornments'
    | 'stockQuantity'
    | 'calculatedPrices'
  > {
  variants?: ProductDetailProduct[];
}

export const getProductDetail = (
  apiClient: ApiClient,
  currency: string,
  decimalPrecision: undefined | number,
  enabledDynamicRules: undefined | string[],
  pricesType: undefined | 'B2B' | 'B2C' | 'B2BForeign',
  region: undefined | string,
  params: {
    id: EntityId;
  },
): Promise<ProductDetailProduct> => {
  return apiClient.get<
    ProductDetailProduct,
    {
      currency: string;
      decimalPrecision: undefined | number;
      enabledDynamicRules: undefined | string[];
      id: EntityId;
      pricesType: undefined | 'B2B' | 'B2C' | 'B2BForeign';
      region: undefined | string;
    }
  >('/v1/products/:id', { id: params.id, currency, decimalPrecision, enabledDynamicRules, pricesType, region });
};

export const productDetailInitialStateFamily = GlobalStateRegister.register(
  atomFamily<ProductDetailProduct | undefined, EntityId>({
    key: 'productDetailInitial',
    default: undefined,
  }),
  'productDetailInitial',
);

export const productDetailQuery = selectorFamily<ProductDetailProduct, EntityId>({
  key: 'productDetail',
  get:
    (productId) =>
    ({ get }) => {
      const initial = get(productDetailInitialStateFamily(productId));
      if (initial) return initial;

      const apiClient = get(apiClientState);
      const currency = get(currencyState);
      const decimalPrecision = get(decimalPrecisionState);
      const enabledDynamicRules = undefined;
      const pricesType = get(pricesTypeState);
      const region = get(regionState);

      return getProductDetail(apiClient, currency, decimalPrecision, enabledDynamicRules, pricesType, region, {
        id: productId,
      });
    },
});

export const getProductDetailLongDescription = (
  apiClient: ApiClient,
  params: ProductLongDescriptionParams,
): Promise<any> => {
  return apiClient.get('/v1/products/:id/long-description/:locale', params);
};

export const productLongDescriptionQueryFamily = selectorFamily<null | { body?: string; id: string }, string>({
  key: 'productLongDescriptionQueryFamily',
  get:
    (productId) =>
    ({ get }) => {
      const apiClient = get(apiClientState);
      const locale = get(localeState);

      return getProductDetailLongDescription(apiClient, { id: productId, locale });
    },
});

export interface ProductLongDescriptionParams {
  id: string;
  locale: string;
}
