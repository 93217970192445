import { createSubjectId, PricesType, PricingCart, PricingCartSubject } from '@sprinx/pricing-core';
import PricingConstants from '@sprinx/pricing-core/constants';
import { Multicurrency } from '@sprinx/react-globalize/types';
import { useCallback, useMemo } from 'react';
import { atom, constSelector, selector, useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import invariant from 'tiny-invariant';
import { EntityId, Product, ProductQuantityLimits, ProductRecord } from '../@sprinx/knihovka-types';
import { GlobalStateRegister } from '../@sprinx/react-after-razzle/stateStore';
import { monitorStockAvailability } from '../config';
import usePlaces from '../hooks/usePlaces';
import useTranslateWithFallback from '../hooks/useTranslateWithFallback';
import { deliverySelectPopupOpenState } from '../state/atoms';
import { apiClientState, currencyState, decimalPrecisionState, pricesTypeState, useApiClient } from './appState';
import { shippingsState, getShippings } from './shipping/shipping';
import { shoppingCustomerSelector, shoppingSelectedDeliveryPlaceState } from './shopping';
import { useShowSnackbarMessage } from './snackbars';

// Shopping settings

/**
 * IF `true` can not decrement in cart to 0 => remove cart item by delete/remove action.
 */
export const preventDecrementRemoveSubjectState = constSelector(true);
export const usePreventDecrementRemoveSubject = (): boolean => {
  return useRecoilValue(preventDecrementRemoveSubjectState);
};

/**
 * Keeps state of shopping cart.
 */
export const shoppingCartState = GlobalStateRegister.register(
  atom<ShoppingCart | undefined>({
    key: 'shoppingCart',
    default: undefined,
  }),
);

export const shoppingCartnumberOfSubjectsSelector = selector({
  key: 'shoppingCartnumberOfSubjectsSelector',
  get: ({ get }) => {
    const cart = get(shoppingCartState);
    return cart?.cartContent.subjects.length || 0;
  },
});

export const shoppingCartHasPalettesSelector = selector({
  key: 'shoppingCartHasPalettes',
  get: ({ get }) => {
    const cart = get(shoppingCartState);
    const { subjects } = cart?.cartContent || { cartContent: {} };
    return (
      subjects &&
      subjects.some((data) => data && data.price && data.product['extra'] && data.product['extra'].pack === 'PALETA')
    );
  },
});

export const shoppingCartAreAllPalettesSelector = selector({
  key: 'shoppingCartAreAllPalettes',
  get: ({ get }) => {
    const cart = get(shoppingCartState);
    const { subjects } = cart?.cartContent || { cartContent: {} };
    return (
      subjects &&
      subjects.every((data) => data && data.price && data.product['extra'] && data.product['extra'].pack === 'PALETA')
    );
  },
});

export const shoppingCartWeightSelector = selector({
  key: 'shoppingCartWeight',
  get: ({ get }) => {
    const cart = get(shoppingCartState);
    const subjects = cart?.cartContent?.subjects ?? [];

    const totalCartWeight = subjects.reduce((acc, { quantity, product }) => {
      const weight = (product as ProductRecord)?.weight?.value ?? 0;
      const totalProductWeight = quantity * weight;
      return acc + totalProductWeight;
    }, 0);

    return totalCartWeight;
  },
});

export const shoppingCartAmoutForFreeShippingSelector = selector({
  key: 'shoppingCartAmoutForFreeShipping',
  get: ({ get }) => {
    const shippings = get(shippingsState) as Array<ShoppingCartProduct & { limits: Array<[string, any]> }>;
    const cart = get(shoppingCartState);
    const weight = get(shoppingCartWeightSelector);
    const currency = get(currencyState);

    const palleteOnly = cart?.cartContent.subjects.find((s: any) => s.product?.extra?.palletOnly);

    const allowedShippings = shippings.filter((i) => {
      if (
        palleteOnly &&
        i.limits.find((limit) => limit[0] === 'cart' && limit[1].includes('gte') && limit[1].at(-1) === 0)
      )
        return false;
      return i.limits.find((limit) => limit[0] === 'cart' && limit[1].includes('lte') && limit[1].at(-1) > weight);
    });

    if (!allowedShippings.length) return null;

    const hasOnlyPickup = allowedShippings.length === 1 && allowedShippings[0].sku === 'osobni-odber-cz';
    if (hasOnlyPickup) return null;

    const totalSubjects = cart?.cartContent?.totalSubjects;
    const minFreeShippingLimit = shippings
      .reduce(
        // @ts-ignore
        (acc: any[], i) => [...acc, ...(i?.extra?.freeShippingLimits?.filter((i) => i.currency === currency) ?? [])],
        [],
      )
      .sort((a: any, b: any) => a.amount - b.amount)?.[0]?.amount;

    if (!minFreeShippingLimit || (totalSubjects && totalSubjects >= minFreeShippingLimit)) return 0;

    if (!totalSubjects) return minFreeShippingLimit;

    return minFreeShippingLimit - totalSubjects;
  },
});

export const useShoppingCartPresentInCart = (): ((params: {
  product?: string | { id: string };
  ruleCode?: string;
  subjectId?: string;
}) => [quantity: number | undefined, subjectId: string]) => {
  const cart = useRecoilValue(shoppingCartState);

  return useCallback<
    (params: {
      product?: string | { id: string };
      ruleCode?: string;
      subjectId?: string;
    }) => [quantity: number | undefined, subjectId: string]
  >(
    ({ product, ruleCode, subjectId: pSubjectId }) => {
      invariant(
        pSubjectId || product,
        'AppShoppingProvider presentInCart requires at least one of subjectId or product have to be defined',
      );

      const subjectId = pSubjectId || createSubjectId(ensureId(product as any), ruleCode);
      return [(cart?.flatCart[subjectId] || {}).quantity, subjectId];
    },
    [cart?.flatCart],
  );
};

export const useShoppingCartQuantityInCart = (): ((params: {
  cartSubject?: { id: string; product: { id: string } };
  product?: { id: string };
  ruleCode?: string;
}) => number | undefined) => {
  const presentInCart = useShoppingCartPresentInCart();

  return useCallback<
    (params: {
      cartSubject?: { id: string; product: { id: string } };
      product?: { id: string };
      ruleCode?: string;
    }) => number | undefined
  >(
    ({ cartSubject, product, ruleCode }) => {
      const [qty] = presentInCart(
        cartSubject ? { subjectId: cartSubject.id, product: cartSubject.product } : { product, ruleCode },
      );
      return qty;
    },
    [presentInCart],
  );
};

/**
 * Returns handler to add quantity of product or cart subject to cart
 */
export function useAddToCart(
  {
    cartSubject,
    forcePreventDecrement = false,
    product,
    ruleCode,
  }: {
    cartSubject?: ShoppingCartSubject;
    forcePreventDecrement?: boolean;
    product?: AddToCartProduct;
    ruleCode?: string;
  },
  customQuantityLimitDefaults?: Partial<ProductQuantityLimits>,
): {
  addToCart: (nextQuantity: number) => Promise<any>;
  quantity: number | undefined;
} & ProductQuantityLimits {
  invariant(
    cartSubject || product,
    'useAddToCart requires at least one of `cartSubject` or `product` props to be defined.',
  );
  const updateCart = useUpdateCart();
  const preventDecrementRemoveSubject = usePreventDecrementRemoveSubject();
  const quantityInCart = useShoppingCartQuantityInCart();
  const cart = useRecoilValue(shoppingCartState);
  const places = usePlaces();
  const setSelectDeliveryPopupOpen = useSetRecoilState(deliverySelectPopupOpenState);
  const selectedDeliveryPlace = useRecoilValue(shoppingSelectedDeliveryPlaceState);

  const customer = useRecoilValue(shoppingCustomerSelector);
  const apiClient = useApiClient();
  const setShippings = useSetRecoilState(shippingsState);

  const quantity = cartSubject ? cartSubject.quantity : quantityInCart({ product, ruleCode });

  // console.log('cartSubject', cartSubject);

  const productMaxQuantity = product?.stockQuantity?.detail?.[0]?.quantity;

  const handleAddToCart = useCallback<(nextQuantity: number) => Promise<any>>(
    (nextQuantity) => {
      const callShipping = async () => {
        if (customer && cart?.id && (product?.extra?.palletOnly || cartSubject?.product?.['extra'].palletOnly)) {
          const response = await getShippings(apiClient, customer, cart?.id);

          if (response?.rows.length) setShippings(response.rows);
        }
      };

      if (
        monitorStockAvailability &&
        (product?.stockQuantity?.available === false ||
          (productMaxQuantity != null && productMaxQuantity < nextQuantity))
      ) {
        return Promise.resolve();
      }
      if (!selectedDeliveryPlace && (cart?.cartContent?.subjects?.length ?? 0) === 0 && places.length > 1) {
        setSelectDeliveryPopupOpen(true);
      }

      if (preventDecrementRemoveSubject && !forcePreventDecrement && nextQuantity <= 0) {
        return Promise.resolve();
      }

      if (cartSubject) {
        return updateCart({ subjectId: cartSubject.id, product: cartSubject.product, quantity: nextQuantity }).then(
          callShipping,
        );
      }

      if (product) {
        return updateCart({ product, quantity: nextQuantity, ruleCode }).then(callShipping);
      }

      return Promise.resolve();
    },
    [
      product,
      productMaxQuantity,
      selectedDeliveryPlace,
      cart?.cartContent?.subjects?.length,
      cart?.id,
      places.length,
      preventDecrementRemoveSubject,
      forcePreventDecrement,
      cartSubject,
      customer,
      apiClient,
      setShippings,
      setSelectDeliveryPopupOpen,
      updateCart,
      ruleCode,
    ],
  );

  const o: any = cartSubject || product || {};
  const quantityStep = o?.quantityStep || 1;

  const quantityMax = monitorStockAvailability
    ? Math.floor(
        Number(
          (typeof cartSubject?.product !== 'string' && cartSubject?.product?.stockQuantity?.detail?.[0]?.quantity) ||
            product?.stockQuantity?.detail?.[0]?.quantity ||
            o.quantityMax,
        ) / quantityStep,
      ) * quantityStep || quantityStep
    : o.quantityMax;

  const quantyLimits = useProductQuantityLimitsDefaults(
    {
      quantityDefault: o.quantityDefault,
      quantityMax,
      quantityMin: o.quantityMin,
      quantityStep: o.quantityStep,
      quantityStepRounding: o.quantityStepRounding,
    },
    customQuantityLimitDefaults,
  );

  return {
    addToCart: handleAddToCart,
    quantity,
    ...quantyLimits,
  };
}

export function useRemoveFromCart(options: {
  cartSubject?: ShoppingCartSubject;
  product?: AddToCartProduct;
  ruleCode?: string;
}): () => Promise<any> {
  const { addToCart } = useAddToCart({ ...options, forcePreventDecrement: true });
  return useCallback<() => Promise<any>>(() => {
    return addToCart(0);
  }, [addToCart]);
}

/**
 * Keeps after app and cart initialization decisition. If `state !=== undefined` is required
 * to do some decision. Merge carts, create new one or keep current.
 */
export const shoppingCartInitializationDecisionState = GlobalStateRegister.register(
  atom<ShoppingCartPreparationResult | undefined>({
    key: 'shoppingCartInitializationDecision',
    default: undefined,
  }),
);

/**
 * Shopping cart initialization
 */

export function useShoppingCartPrepareForSessionCall(): () => Promise<ShoppingCartPreparationResult> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    () => apiClient.post<ShoppingCartPreparationResult, {}>('/v1/shopping-cart/prepare-cart-for-session', {}),
    [apiClient],
  );
}

/**
 * Shopping cart merge decision.
 */

export function useShoppingCartProcessUserDecisionCall(): (params: any) => Promise<ShoppingCartUserDecitionResult> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (params: ShoppingCartProcessUserDecisionCallParams) =>
      apiClient.post<ShoppingCartUserDecitionResult, ShoppingCartProcessUserDecisionCallParams>(
        '/v1/shopping-cart/process-user-decition',
        params,
      ),
    [apiClient],
  );
}

/**
 * Update shopping cart
 */
export function useShoppingCartUpdateCartCall(): (
  cartId: ShoppingCart['id'] | undefined | null,
  data: ShoppingCartUpdateCartDataItemParams | ShoppingCartUpdateCartDataItemParams[],
) => Promise<any> {
  const apiClient = useRecoilValue(apiClientState);
  const currency = useRecoilValue(currencyState);
  const decimalPrecision = useRecoilValue(decimalPrecisionState);
  const pricesType = useRecoilValue(pricesTypeState);

  return useCallback(
    (
      cartId: ShoppingCart['id'] | undefined | null,
      data: ShoppingCartUpdateCartDataItemParams | ShoppingCartUpdateCartDataItemParams[],
    ) => {
      if (!cartId) {
        throw new Error('CART not Found');
      }
      return apiClient.post<ShoppingCartManipulationResult, ShoppingCartUpdateCartCallParams>(
        '/v1/shopping-cart/update',
        {
          cartId,
          currency,
          data,
          decimalPrecision,
          enabledDynamicRules: undefined,
          priceGroup: undefined,
          pricesType: pricesType,
        },
      );
    },
    [apiClient, currency, decimalPrecision, pricesType],
  );
}

/**
 * Reset shopping cart.
 */
export function useShoppingCartReserCartCall(): (
  cartId: ShoppingCart['id'] | undefined | null,
  preventMessageEmit?: boolean,
) => Promise<ShoppingCartManipulationResult> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (cartId: ShoppingCart['id'] | undefined | null, preventMessageEmit?: boolean) => {
      if (!cartId) {
        throw new Error('CART not Found');
      }

      return apiClient.post<
        ShoppingCartManipulationResult,
        { cartId: ShoppingCart['id']; preventMessageEmit?: boolean }
      >('/v1/shopping-cart/reset', {
        cartId,
        preventMessageEmit,
      });
    },
    [apiClient],
  );
}

export const useUpdateCart = (): ((
  data: ShoppingCartUpdateCartDataItemParams | ShoppingCartUpdateCartDataItemParams[],
) => Promise<any>) => {
  const [cart, setCart] = useRecoilState(shoppingCartState);
  const updateCartCall = useShoppingCartUpdateCartCall();
  const showCartUpdateMessage = useShowCartUpdateMessage();

  return useCallback(
    (data: ShoppingCartUpdateCartDataItemParams | ShoppingCartUpdateCartDataItemParams[]) => {
      const mappedData = (Array.isArray(data) ? data : [data]).map((d) => ({
        ...d,
        product: typeof d.product === 'object' ? d.product.id : d.product,
      }));
      return updateCartCall(cart?.id, mappedData)
        .then((res) => {
          setCart(res.cart);
          if (res.updateStatus.msg) {
            showCartUpdateMessage(res.updateStatus);
          }
        })
        .catch((err) => {
          // TODO: ak nastala chyba updatu, tak co???
          console.error('Update Cart Error', err);
        });
    },
    [cart?.id, setCart, showCartUpdateMessage, updateCartCall],
  );
};

export const useCartReset = (): ((disableMessage?: boolean) => Promise<any>) => {
  const [cart, setCart] = useRecoilState(shoppingCartState);
  const resetCartCall = useShoppingCartReserCartCall();
  const showCartUpdateMessage = useShowCartUpdateMessage();

  return useCallback(
    (disableMessage?: boolean) => {
      return resetCartCall(cart?.id, disableMessage).then((res) => {
        setCart(res.cart);
        if (res.updateStatus.msg && !disableMessage) {
          showCartUpdateMessage(res.updateStatus);
        }
      });
    },
    [cart?.id, resetCartCall, setCart, showCartUpdateMessage],
  );
};

export const useShoppingCartEnableIndirectSubject = (): ((indirectSubjectId: string) => void) => {
  const updateCart = useUpdateCart();
  const cart = useRecoilValue(shoppingCartState);

  return useCallback(
    (indirectSubjectId: string) => {
      if (cart?.flatCart[indirectSubjectId]) {
        updateCart({ subjectId: indirectSubjectId, enableIndirect: true });
      }
    },
    [cart?.flatCart, updateCart],
  );
};

export const useShoppingCartSetPayment = (): ((
  cartPaymentProduct: string | ShoppingCartProduct | undefined,
) => void) => {
  const updateCart = useUpdateCart();

  return useCallback<(cartPaymentProduct: string | ShoppingCartProduct | undefined) => void>(
    (cartPaymentProduct) => {
      const cartPayment = cartPaymentProduct
        ? { product: cartPaymentProduct }
        : { product: PricingConstants.PAYMENT_DEFAULT_PRODUCT };
      return updateCart({ ...cartPayment, quantity: 1, subjectId: PricingConstants.PAYMENT_ID });
    },
    [updateCart],
  );
};

export const useShoppingCartSetShipping = (): ((
  cartShippingProduct: string | ShoppingCartProduct | undefined,
  quantityOfShipping: number,
) => Promise<any>) => {
  const updateCart = useUpdateCart();

  return useCallback<
    (cartShippingProduct: string | ShoppingCartProduct | undefined, quantityOfShipping: number) => Promise<any>
  >(
    (cartShippingProduct, quantityOfShipping) => {
      const cartShipping = cartShippingProduct ? { product: cartShippingProduct } : {};
      const shippingItem = { ...cartShipping, quantity: quantityOfShipping, subjectId: PricingConstants.SHIPPING_ID };
      return updateCart(
        quantityOfShipping === 0
          ? [
              shippingItem,
              {
                product: PricingConstants.PAYMENT_DEFAULT_PRODUCT,
                quantity: 1,
                subjectId: PricingConstants.PAYMENT_ID,
              },
            ]
          : shippingItem,
      );
    },
    [updateCart],
  );
};

export const justifyQuantity = (
  { quantityMax, quantityMin, quantityStep, quantityStepRounding }: Omit<ProductQuantityLimits, 'quantityDefault'>,
  quantity: number | undefined,
): number => {
  const getRoundingFunction = (quantityStepRounding: ProductQuantityLimits['quantityStepRounding']) => {
    if (quantityStepRounding === 'down') {
      return Math.floor;
    }

    // quantityStepRounding === 'up'
    return Math.ceil;
  };

  if (quantity === undefined || quantity <= 0) return 0;

  if (quantityStep > 1) {
    const fraction = quantity / quantityStep;
    const fold = getRoundingFunction(quantityStepRounding)(fraction);

    return Math.min(Math.max(fold * quantityStep, quantityMin), quantityMax);
  }

  return Math.min(Math.max(quantity, quantityMin), quantityMax);
};

export function useJustifyQuantity({
  quantityMax,
  quantityMin,
  quantityStep,
  quantityStepRounding,
}: Omit<ProductQuantityLimits, 'quantityDefault'>): (quantity: number | undefined) => number {
  return useCallback<(quantity: number | undefined) => number>(
    (quantity) => justifyQuantity({ quantityMax, quantityMin, quantityStep, quantityStepRounding }, quantity),
    [quantityMax, quantityMin, quantityStep, quantityStepRounding],
  );
}

export function productQuantityLimitsDefaults(
  productLimits: Partial<ProductQuantityLimits>,
  customDefaults?: Partial<ProductQuantityLimits>,
): ProductQuantityLimits {
  const {
    quantityMax = customDefaults?.quantityMax || 99999, // default was: Number.MAX_SAFE_INTEGER
    quantityMin = customDefaults?.quantityMin || 1,
    quantityStep = customDefaults?.quantityStep || 1,
    quantityStepRounding = customDefaults?.quantityStepRounding || 'up',
  } = productLimits;

  const quantityDefault: number =
    productLimits.quantityDefault || customDefaults?.quantityDefault || productLimits?.quantityMin || 1;

  return { quantityDefault, quantityMax, quantityMin, quantityStep, quantityStepRounding };
}

export function useProductQuantityLimitsDefaults(
  productLimits: Partial<ProductQuantityLimits>,
  customDefaults?: Partial<ProductQuantityLimits>,
): ProductQuantityLimits {
  return useMemo<ProductQuantityLimits>(
    () => productQuantityLimitsDefaults(productLimits, customDefaults),
    [customDefaults, productLimits],
  );
}

export const useShowCartUpdateMessage = (): ((updateStatus: ShoppingCartManipulationResultUpdateStatus) => void) => {
  const t = useTranslateWithFallback();
  const showSnackbarMessage = useShowSnackbarMessage();
  return useCallback(
    (updateStatus: ShoppingCartManipulationResultUpdateStatus): void => {
      const statusToText = {
        'all-removed': () => t('shoppingCart/messages/cartManipulations/allRemoved'),
        'item-added': () => t('shoppingCart/messages/cartManipulations/itemAdded', { number: updateStatus.qty || 0 }),
        'item-removed': () =>
          t('shoppingCart/messages/cartManipulations/itemRemoved', { number: updateStatus.qty || 0 }),
        'quantity-chaged': () => t('shoppingCart/messages/cartManipulations/quantityChaged'),
      };

      if (!updateStatus.msg) return;

      const msg = statusToText[updateStatus.msg]?.();

      if (!msg) return;

      showSnackbarMessage(msg, {
        variant: 'info',
        preventDuplicate: true,
        autoHideDuration: 2000,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    },
    [showSnackbarMessage, t],
  );
};

/* *********************************************************************************************************************
 * INTERNALS
 */
function ensureId(s: string | { id: string }): string {
  if (typeof s === 'string') return s;
  if (s && s !== null && s.id) return s.id;

  throw new Error('ensureId requires object with id prop or no empty string.');
}

/* *********************************************************************************************************************
 * TYPES
 */

export type ShoppingCartProduct = Product<ProductRecord, 'prices' | 'parameters'>;
export type ShoppingCartSubject = PricingCartSubject<ShoppingCartProduct>;
export type ShoppingCartContent = PricingCart<ShoppingCartProduct>;

export type ShoppingCartPaymentProduct = ShoppingCartProduct & { extra?: { paymentTypeLevel?: number | null } };
export interface ShoppingCart {
  cartContent: ShoppingCartContent;
  extra: shoppingCartExtra;
  flatCart: Record<string, ShoppingCartSubject>;
  id: EntityId;
  owner?: string;
  sessions: string[];
  state: ShoppingCartState;
  subjectFindIndex: string[];
  taxonomies: string[];
}

export interface shoppingCartExtra {
  orderProps: shoppingCartExtraOrderProps;
}

export interface shoppingCartExtraOrderProps {
  contact: string;
  extra: object;
  invoiceContact: shoppingCartExtraOrderPropsInvoiceContact;
  noteForSeller?: string;
  postAddress: string;
  shipping: ShoppingCartProduct;
}

export interface shoppingCartExtraOrderPropsInvoiceContact {
  _realm: string;
  id: string;
  name: string;
  type: string;
}

export type ShoppingCartState = 'active' | 'parked' | 'canceled' | 'approved';

export interface ShoppingCartPreparationResult {
  cart: ShoppingCart | null;
  error?: any;
  status?: ShoppingCartPreparationResultStatus | undefined;
}
export type ShoppingCartPreparationResultStatus = 'cart-in-other-session';

export interface ShoppingCartUpdateCartCallParams {
  cartId: string;
  currency: string;
  data: ShoppingCartUpdateCartDataItemParams | ShoppingCartUpdateCartDataItemParams[];
  decimalPrecision?: number;
  /**
   * List of dynamic rules, that are enabled...
   */
  enabledDynamicRules?: string[];
  priceGroup?: string;
  pricesType?: PricesType;
}

export interface ShoppingCartUpdateCartDataItemParams {
  enableIndirect?: boolean;
  product?: string | AddToCartProduct;
  quantity?: number;
  ruleCode?: string;
  subjectId?: string;
}

interface ProductStockQuantities {
  stockQuantity?: {
    available?: boolean;
    detail?: Array<{
      quantity?: number;
      state?: string;
      warehouse?: string;
    }>;
  };
}

export interface AddToCartProduct extends Partial<ProductQuantityLimits>, Partial<ProductStockQuantities> {
  extra?: any;
  id: string;
  listPrice?: Multicurrency;
  listPriceWithTax?: Multicurrency;
  sku: string;
}

export interface ShoppingCartManipulationResult {
  cart: ShoppingCart;
  updateStatus: ShoppingCartManipulationResultUpdateStatus;
}

export interface ShoppingCartManipulationResultUpdateStatus {
  msg: ShoppingCartUdateCartShowMessage | null;
  qty?: number;
}

export type ShoppingCartUdateCartShowMessage = 'all-removed' | 'item-added' | 'item-removed' | 'quantity-chaged';

export interface ShoppingCartResetCartCallParams {
  cartId: string;
}

export type ShoppingCartDecisionChoices = 'empty' | 'merge' | 'take-over';

export interface ShoppingCartProcessUserDecisionCallParams {
  decision: ShoppingCartDecisionChoices;
  mergeWith?: {
    /** CartId to merge with */
    id: string;

    /**
     * TODO: not finaly specified
     *
     * Subject of cart to merge
     */
    subjects?: Record<string, any>[];
  };
  previousCartId: string;
}

export interface ShoppingCartUserDecitionResult {
  cart: ShoppingCart | null;
  error?: any;
  status: ShoppingCartUserDecitionResultStatus | undefined;
}

export type ShoppingCartUserDecitionResultStatus = 'created' | 'merged' | 'taked-over';
