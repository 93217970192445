import { atom, selector } from 'recoil';
import { ApiClient } from '../@sprinx/react-after-razzle';
import { apiClientState } from './appState';

export type CustomerNotification = { body: string; show: true; title: string } | { show: false };

export const customerNotificationOpenState = atom<boolean>({
  default: false,
  key: 'customerNotificationOpen',
});

export const getCustomerNotification = (apiClient: ApiClient): Promise<CustomerNotification> => {
  return apiClient.get<CustomerNotification, {}>('/v1/content/notification', {}).catch(() => ({ show: false }));
};

export const customerNotification = selector<CustomerNotification | null>({
  key: 'customerNotification',
  get: ({ get }) => {
    const apiClient = get(apiClientState);

    return getCustomerNotification(apiClient);
  },
});
